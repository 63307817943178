var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bg-white p-3 rounded shadow-sm"},[_c('h1',[_vm._v("KGD")]),_c('h3',[_vm._v("Screening Anti Body")]),_c('b-form-group',{staticClass:"mt-5 w-100",attrs:{"label":"Masukkan Barcode","label-for":"barcode-input"}},[_c('b-form-input',{attrs:{"id":"barcode-input","type":"text","placeholder":"Masukkan barcode"},on:{"input":_vm.debouncedCheckingBarcodes},model:{value:(_vm.formData.barcode),callback:function ($$v) {_vm.$set(_vm.formData, "barcode", $$v)},expression:"formData.barcode"}})],1),_c('b-table',{attrs:{"responsive":"","items":_vm.tableForm.items,"fields":_vm.tableForm.fields,"busy":_vm.tableForm.isBusy},scopedSlots:_vm._u([{key:"cell(blood_type)",fn:function({ item }){return [_vm._v(" "+_vm._s(item.blood_type.replace("+", " Pos").replace("-", " Neg"))+" ")]}},{key:"cell(status)",fn:function({ item, index }){return [_c('b-form-select',{attrs:{"options":[
            { value: 'negative', text: 'Negative' },
            { value: 'positive 1', text: 'Positive 1' },
            { value: 'positive 2', text: 'Positive 2' },
            { value: 'positive 3', text: 'Positive 3' },
            { value: 'positive 4', text: 'Positive 4' },
          ]},model:{value:(_vm.tableForm.items[index].status),callback:function ($$v) {_vm.$set(_vm.tableForm.items[index], "status", $$v)},expression:"tableForm.items[index].status"}})]}},{key:"cell(action)",fn:function({ item, index }){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.tableForm.items.splice(index, 1)}}},[_c('i',{staticClass:"ri-delete-bin-6-line"})])]}}])}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary"},on:{"click":_vm.saveData}},[_vm._v("Simpan")])],1)],1),_c('section',{staticClass:"bg-white p-3 rounded shadow-sm mt-5"},[_c('h3',[_vm._v("Daftar Screening Anti Body")]),_c('div',{staticClass:"d-flex align-items-end justify-content-between"},[_c('p',{staticClass:"mb-0 mr-3"},[_vm._v("Semua ("+_vm._s(_vm.tableList.totalRows)+")")]),_c('b-input-group',{staticClass:"w-50"},[_c('b-input-group-prepend',[_c('b-button',{staticClass:"border-right-0 pr-0 pl-2",attrs:{"variant":"outline-black-50"}},[_c('i',{staticClass:"ri-search-line remix-icon text-primary"})])],1),_c('b-form-input',{staticClass:"border-left-0",attrs:{"type":"search","placeholder":"Search"},on:{"input":_vm.debounceSearch},model:{value:(_vm.tableList.searchQuery),callback:function ($$v) {_vm.$set(_vm.tableList, "searchQuery", $$v)},expression:"tableList.searchQuery"}})],1)],1),_c('b-table',{attrs:{"responsive":"","items":_vm.tableList.items,"fields":_vm.tableList.fields,"busy":_vm.tableList.isBusy}}),_c('b-pagination',{attrs:{"align":"right","total-rows":_vm.tableList.totalRows,"per-page":_vm.tableList.perPage},on:{"change":_vm.changePage},model:{value:(_vm.tableList.currentPage),callback:function ($$v) {_vm.$set(_vm.tableList, "currentPage", $$v)},expression:"tableList.currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }