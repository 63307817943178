<template>
  <div>
    <section class="bg-white p-3 rounded shadow-sm">
      <h1>KGD</h1>
      <h3>Screening Anti Body</h3>

      <b-form-group
        class="mt-5 w-100"
        label="Masukkan Barcode"
        label-for="barcode-input"
      >
        <b-form-input
          id="barcode-input"
          v-model="formData.barcode"
          type="text"
          placeholder="Masukkan barcode"
          @input="debouncedCheckingBarcodes"
        />
      </b-form-group>

      <b-table
        responsive
        :items="tableForm.items"
        :fields="tableForm.fields"
        :busy="tableForm.isBusy"
      >
        <template #cell(blood_type)="{ item }">
          {{ item.blood_type.replace("+", " Pos").replace("-", " Neg") }}
        </template>
        <template #cell(status)="{ item, index }">
          <b-form-select
            v-model="tableForm.items[index].status"
            :options="[
              { value: 'negative', text: 'Negative' },
              { value: 'positive 1', text: 'Positive 1' },
              { value: 'positive 2', text: 'Positive 2' },
              { value: 'positive 3', text: 'Positive 3' },
              { value: 'positive 4', text: 'Positive 4' },
            ]"
          />
        </template>
        <template #cell(action)="{ item, index }">
          <b-button variant="danger" @click="tableForm.items.splice(index, 1)"
            ><i class="ri-delete-bin-6-line"
          /></b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-end">
        <b-button class="mt-3" variant="primary" @click="saveData"
          >Simpan</b-button
        >
      </div>
    </section>

    <section class="bg-white p-3 rounded shadow-sm mt-5">
      <h3>Daftar Screening Anti Body</h3>
      <div class="d-flex align-items-end justify-content-between">
        <p class="mb-0 mr-3">Semua ({{ tableList.totalRows }})</p>
        <b-input-group class="w-50">
          <b-input-group-prepend>
            <b-button
              variant="outline-black-50"
              class="border-right-0 pr-0 pl-2"
            >
              <i class="ri-search-line remix-icon text-primary" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="tableList.searchQuery"
            type="search"
            placeholder="Search"
            class="border-left-0"
            @input="debounceSearch"
          />
        </b-input-group>
      </div>
      <b-table
        responsive
        :items="tableList.items"
        :fields="tableList.fields"
        :busy="tableList.isBusy"
      >
      </b-table>
      <b-pagination
        align="right"
        v-model="tableList.currentPage"
        :total-rows="tableList.totalRows"
        :per-page="tableList.perPage"
        @change="changePage"
      />
    </section>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BTable,
  BPagination,
  BFormSelect,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import manageBloodShipmentAPI from "../../../../api/blood_shipment/manageBloodShipmentAPI";
import debounce from "lodash/debounce";
import managescreeningantibodyAPI from "../../../../api/managescreeningantibody/managescreeningantibodyAPI";

export default {
  name: "ConfirmBloodType",
  components: {
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      formData: {
        barcode: "",
      },
      tableForm: {
        fields: [
          { key: "barcode", label: "Barcode" },
          { key: "blood_bag_number", label: "Nomor Selang" },
          { key: "bag_type", label: "Jenis Kantong" },
          { key: "blood_type", label: "Golongan Darah" },
          {
            key: "status",
            label: "Status",
          },
          { key: "action", label: "Aksi" },
        ],
        items: [],
        isBusy: false,
      },
      tableList: {
        searchQuery: "",
        fields: [
          { key: "barcode", label: "Barcode" },
          { key: "status", label: "status" },
          { key: "officer_name", label: "Nama Petugas" },
        ],
        items: [],
        isBusy: false,
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
      },
    };
  },
  created() {
    // Gunakan debounce agar fungsi dijalankan hanya setelah input berhenti selama 500ms
    this.debouncedCheckingBarcodes = debounce(this.checkingBarcodes, 500);
  },
  methods: {
    async checkingBarcodes() {
      if (this.formData.barcode.trim() === "") return; // Hindari eksekusi jika barcode kosong
      try {
        this.tableForm.isBusy = true;
        const { data: response } = await manageBloodShipmentAPI.checkingBarcode(
          {
            barcode: this.formData.barcode,
          }
        );
        // Cek apakah respons valid dan bukan array
        if (response && response.data) {
          // Cek apakah data sudah ada di dalam items
          const existingItem = this.tableForm.items.find(
            (item) => item.barcode === response.data.barcode
          );
          if (!existingItem) {
            // Tambahkan respons baru ke dalam items, tanpa menghapus data yang sudah ada
            this.$bvToast.toast("Barcode ditemukan", {
              title: "Berhasil cek barcode",
              variant: "success",
              solid: true,
            });
            this.tableForm.items.push({
              ...response.data,
              status: "negative", // Default conformation_blood_type untuk dipilih nanti
            });
          } else {
            this.$bvToast.toast("Barcode sudah ditemukan sebelumnya", {
              title: "Cek barcode",
              variant: "warning",
              solid: true,
            });
          }
        } else {
          this.$bvToast.toast("Barcode tidak ditemukan", {
            title: "Cek barcode",
            variant: "danger",
            solid: true,
          });
        }
        this.formData.barcode = ""; // Kosongkan input setelah melakukan pengecekan
      } catch (error) {
        this.$bvToast.toast("Barcode tidak ditemukan", {
          title: "Cek barcode",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.tableForm.isBusy = false;
      }
    },

    changePage(page) {
      this.tableList.currentPage = page;
      this.getList();
    },

    async saveData() {
      const barcodesToSave = this.tableForm.items
        .filter(
          (item) =>
            !this.tableList.items.some(
              (tableItem) => tableItem.barcode === item.barcode
            )
        )
        .map((item) => item.barcode);

      const barcodesAlreadyExist = this.tableForm.items
        .map((item) => item.barcode)
        .filter((barcode) => !barcodesToSave.includes(barcode));

      if (barcodesToSave.length > 0) {
        await managescreeningantibodyAPI.add({
          bloods: this.tableForm.items
            .filter((item) => barcodesToSave.includes(item.barcode))
            .map((item) => ({ barcode: item.barcode, status: item.status })),
        });
      }

      await this.getList();

      this.tableForm.items = [];

      if (barcodesToSave.length > 0) {
        this.$bvToast.toast(
          `Data berhasil disimpan :\n${barcodesToSave.join(",\n")}`,
          {
            title: "Success",
            variant: "success",
            solid: true,
          }
        );
      }

      if (barcodesAlreadyExist.length > 0) {
        this.$bvToast.toast(
          `Data tidak disimpan karena sudah ada di database :\n${barcodesAlreadyExist.join(
            ",\n"
          )}`,
          {
            title: "Peringatan",
            variant: "warning",
            solid: true,
          }
        );
      }
    },
    async getList() {
      const { data: response } = await managescreeningantibodyAPI.getAll({
        search: this.tableList.searchQuery,
        page: this.tableList.currentPage,
        per_page: this.tableList.perPage,
      });

      this.tableList.items = response.data.data;
      this.tableList.totalRows = response.data.total;
    },

    debounceSearch: debounce(function () {
      this.getList();
    }),
  },
  mounted() {
    this.getList();
  },
};
</script>
